import React from "react";
import PropTypes from "prop-types";
import DirectionsImage from "static/images/directionsIcon.png";
import classes from "./user-invitation.scss";
import {
  defineMessages,
  FormattedMessage,
  useIntl,
} from "react-intl";
import { Typography, Button } from '@getgo/chameleon-web-react-wrapper';

const t = defineMessages({
  "user-invitation.icon-text": {
    id: "user-invitation.icon-text",
    defaultMessage: "User invitation icon",
    description: "Hover text to display for the user invitation image",
  },
  "user-invitation.sign-in": {
    id: "user-invitation.sign-in",
    defaultMessage: "Sign In",
    description: "Label for the sign in button",
  },
  "user-invitation.title": {
    id: "user-invitation.title",
    defaultMessage: "Sign in to view the invitation",
    description: "User invitation page title",
  },
  "user-invitation.body-text-p1": {
    id: "user-invitation.body-text-p1",
    defaultMessage:
      "You've been invited to join another account. For more details about your GoTo products, sign in info, and account admin, check your invitation email.",
    description: "Paragraph 1 of body text",
  },
  "user-invitation.body-text-p2": {
    id: "user-invitation.body-text-p2",
    defaultMessage:
      'Been a while since you signed in? We can help you <a>reset your password</a>.',
    description: "Paragraph 2 of body text",
  },
});

const UserInvitation = ({ navToSignIn, resetPwdUrl }) => {
  return (
    <div>
      <div
        id="user-invitation-section"
        className={classes["userInvitationContainer"]}
      >
        <div>
          <div className={`row ${classes["titleRow"]}`}>
            <Typography variant="heading-medium">
              <FormattedMessage {...t["user-invitation.title"]} />
            </Typography>
          </div>
          <div className={`row ${classes["bodyRow"]}`}>
            <Typography variant="body-medium">
              <FormattedMessage {...t["user-invitation.body-text-p1"]} />
            </Typography>
          </div>
          <div className={`row ${classes["bodyRow"]}`}>
            <Typography variant="body-medium">
              <FormattedMessage
                {...t["user-invitation.body-text-p2"]}
                values={{
                  a: (chunks) => <a href={resetPwdUrl} target="_self" rel="noopener noreferrer">{chunks}</a>,
                }}
              />
            </Typography>
          </div>
          <div className={`row ${classes["buttonRow"]}`}>
            <Button
              id="accept-invitation-accept"
              onClick={navToSignIn}
              fullWidth
              role="button"
              size="large"
            >
              <FormattedMessage {...t["user-invitation.sign-in"]} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

UserInvitation.propTypes = {
  navToSignIn: PropTypes.func.isRequired,
  resetPwdUrl: PropTypes.string.isRequired,
};

export default UserInvitation;
