import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactAvatarEditor from "react-avatar-editor";
import {
  FormattedMessage,
  defineMessages,
  useIntl,
} from "react-intl";
import classes from "./personal-info.scss";
import st from "core/shared-translations";
import {
  RotateCcwOutlinedIcon,
  RotateCwOutlinedIcon,
  PictureOutlinedIcon
} from '@getgo/chameleon-icons/react';
import { Button, IconButton, Typography } from "@getgo/chameleon-web-react-wrapper";

const t = defineMessages({
  "avatar-editor.drag-and-zoom-reposition-photo": {
    id: "avatar-editor.drag-and-zoom-reposition-photo",
    defaultMessage: "Drag & zoom to reposition your photo.",
    description:
      "Instruction label to user to position and set the photo's size",
  },
  "avatar-editor.chose-another-photo": {
    id: "avatar-editor.chose-another-photo",
    defaultMessage: "Choose a different photo",
    description:
      "Link text to open the file system explorer to pick a new photo",
  },
});

const AvatarEditor = ({
  userImageUrl,
  handleCanvasImage,
  closeModal,
  showImageSelectorControl,
  doneButtonDisabled = false,
}) => {
  let setEditorRef;
  let intl = useIntl();
  let [imageValues, setImageValues] = useState({
    scale: 1.5,
    rotate: 0,
    borderRadius: 100,
    border: 40,
    width: 220,
    height: 220,
    color: [255, 255, 255, 0.5],
  });

  const handleSave = () => {
    doneButtonDisabled = true;
    const canvasImage = setEditorRef.getImage();
    handleCanvasImage(canvasImage);
  };

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setImageValues({
      ...imageValues,
      scale,
    });
  };

  const rotateLeft = (e) => {
    e.preventDefault();
    setImageValues({
      ...imageValues,
      rotate: imageValues.rotate - 90,
    });
  };

  const rotateRight = (e) => {
    e.preventDefault();
    setImageValues({
      ...imageValues,
      rotate: imageValues.rotate + 90,
    });
  };

  return (
    <div id="avatar-editor-modal" className="modalContent">
      <div className={`row ${classes["editorRow"]}`}>
        <IconButton
          id="rotateLeft"
          labelId="rotate anticlockwise"
          onClick={rotateLeft}
        >
          <RotateCcwOutlinedIcon color="#2C72DE" size="24px" />
        </IconButton>
        <div className="col-xs-6 no-padding" style={{ width: "85%" }}>
          <ReactAvatarEditor
            ref={(editor) => {
              setEditorRef = editor;
            }}
            image={userImageUrl === "" ? undefined : userImageUrl}
            scale={parseFloat(imageValues.scale)}
            border={imageValues.border}
            width={imageValues.width}
            height={imageValues.height}
            rotate={parseFloat(imageValues.rotate)}
            borderRadius={imageValues.borderRadius}
          />
        </div>
        <IconButton id="rotateRight" onClick={rotateRight} labelId="rotate clockwise">
          <RotateCwOutlinedIcon color="#2C72DE" size="24px" />
        </IconButton>
      </div>

      <div className={classes["sliders"]}>
        <PictureOutlinedIcon color="#2A3238B2" size="21px" />
        <span className={`${classes["zoomControl"]}`}>
          <input
            name="scale"
            type="range"
            onChange={handleScale}
            min="1"
            max="2"
            step="0.01"
            defaultValue="1.5"
            className={classes["imageScaler"]}
          />
        </span>
        <PictureOutlinedIcon color="#2A3238B2" size="27px" />
      </div>

      <div className={classes["choseAnotherPictureText"]}>
        <Typography variant="body-small">
          <a
            href="#"
            onClick={showImageSelectorControl}
            className={`${classes["resetToDefaultText"]} ${classes["pictureSelector"]}`}
          >
            {intl.formatMessage(t["avatar-editor.chose-another-photo"])}
          </a>
        </Typography>

      </div>
      <div className={classes["avatarOuterContainer"]}>
        <div className={classes["avatarActions"]}>
          <Button onClick={handleSave} disabled={doneButtonDisabled}>
            <FormattedMessage {...st["shared.save"]} />
          </Button>
          <Button onClick={closeModal} variant="tertiary">
            <FormattedMessage {...st["shared.cancel"]} />
          </Button>
        </div>
      </div>
    </div>
  );
};

AvatarEditor.propTypes = {
  userImageUrl: PropTypes.string,
  handleCanvasImage: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  showImageSelectorControl: PropTypes.func.isRequired,
  doneButtonDisabled: PropTypes.bool.isRequired,
};

export default AvatarEditor;
