import React from "react";
import PropTypes from "prop-types";
import LastPassLogo from "static/images/svg/lastpass_logo.svg";
import {
  FormattedMessage,
  defineMessages,
} from "react-intl";
import classes from "./signin-security.scss";
import appconfig from "config/appconfig";
import st from "core/shared-translations";
import BoxedContainer from "components/boxed-container-view";
import {
  EditOutlinedIcon,
  GoogleIcon,
  FacebookIcon,
  LinkedinIcon,
  LinkOutlinedIcon,
  MicrosoftIcon,
  AppleIcon,
  JoinOutlinedIcon
} from "@getgo/chameleon-icons/react";
import { Typography } from "@getgo/chameleon-web-react-wrapper";
import EnterpriseSignIn from "../containers/enterprise-signin-container";
import SocialConnectToLink from "components/social-connect-to-link";
import ResponsiveDialog from 'components/dialog-view.js';

const t = defineMessages({
  "social-accounts.social-accounts": {
    id: "social-accounts.social-accounts",
    defaultMessage: "Other ways to sign in",
    description: "Title string for the Social Accounts section",
  },
  "social-accounts.info-text_line": {
    id: "social-accounts.info-text_line",
    defaultMessage:
      "Save time using an existing account. We promise to use your info only to sign in.",
    description: "Information string for the Social Accounts section",
  },
  "social-accounts.connected-as": {
    id: "social-accounts.connected-as",
    defaultMessage: "Connected as{br}<b>{socialId}</b>",
    description:
      "Label text preceding the user's email when user is connected to the social account ",
  },
  "social-accounts.connected-account": {
    id: "social-accounts.connected-account",
    defaultMessage: "Connected accounts",
    description: "heading for connnected account list",
  },
});

export const SocialAccounts = ({
  googleEmail,
  googleLink,
  facebookEmail,
  facebookLink,
  linkedinEmail,
  linkedinLink,
  microsoftEmail,
  microsoftLink,
  lastpassEmail,
  lastpassLink,
  appleEmail,
  appleLink,
  disconnectSocialAccount,
  isViewOnly = false,
  modalIsOpen,
  editLinkClick,
  closeModal,
  showEditLink
}) => {
  return (
    <div>
      <div id="socialOuterContainer">
        <BoxedContainer
          title={<FormattedMessage {...st["shared.signin"]} />}
          buttonIcon={<EditOutlinedIcon />}
          buttonText={<FormattedMessage {...st["shared.edit"]} />}
          onClick={editLinkClick}
          boxIcon={<JoinOutlinedIcon color="#000000" />}
        >
          {(googleEmail || facebookEmail ||
            linkedinEmail || microsoftEmail ||
            lastpassEmail || appleEmail) &&
              <div id="connnectedGroupConatiner" className={classes["connectedAccountsGroup"]}>
                <Typography variant="caption-medium" color="type-color-secondary">
                  <FormattedMessage {...t['social-accounts.connected-account']} />
                </Typography>
                <div className={classes["socialCollection"]}>
                  {googleEmail &&
                    <div className={classes["connectedAccount"]}>
                      <GoogleIcon size="24px" />
                      <LinkOutlinedIcon size="24px" />
                      <Typography variant="body-small">{googleEmail}</Typography>
                    </div>}
                  {facebookEmail &&
                    <div className={classes["connectedAccount"]}>
                      <FacebookIcon size="24px" />
                      <LinkOutlinedIcon size="24px" />
                      <Typography variant="body-small">{facebookEmail}</Typography>
                    </div>}
                  {linkedinEmail &&
                    <div className={classes["connectedAccount"]}>
                      <LinkedinIcon size="24px" />
                      <LinkOutlinedIcon size="24px" />
                      <Typography variant="body-small">{linkedinEmail}</Typography>
                    </div>}
                  {microsoftEmail &&
                    <div className={classes["connectedAccount"]}>
                      <MicrosoftIcon size="24px" />
                      <LinkOutlinedIcon size="24px" />
                      <Typography variant="body-small">{microsoftEmail}</Typography>
                    </div>}
                  {appleEmail &&
                    <div className={classes["connectedAccount"]}>
                      <AppleIcon size="24px" />
                      <LinkOutlinedIcon size="24px" />
                      <Typography variant="body-small">{appleEmail}</Typography>
                    </div>}
                  {lastpassEmail &&
                    <div className={classes["connectedAccount"]}>
                      <img className={classes["socialLogo"]} src={LastPassLogo} alt="lastpass" />
                      <LinkOutlinedIcon size="24px" />
                      <Typography variant="body-small">{lastpassEmail}</Typography>
                    </div>}
                </div>
              </div>}

          {(!lastpassEmail || !googleEmail || !facebookEmail ||
            !linkedinEmail || !microsoftEmail || !appleEmail) &&
              <Typography variant="caption-medium" color="type-color-secondary">
                <FormattedMessage {...t['social-accounts.social-accounts']} />
              </Typography>}
          <div className={classes["iconGroup"]}>
            {!lastpassEmail && <img className={classes["socialLogo"]} src={LastPassLogo} alt="lastpass" />}
            {!googleEmail && <GoogleIcon size="24px" />}
            {!facebookEmail && <FacebookIcon size="24px" />}
            {/* {!linkedinEmail && <LinkedinIcon size="24px" />} */}
            {!microsoftEmail && <MicrosoftIcon size="24px" />}
            {!appleEmail && <AppleIcon size="24px" />}
          </div>
          <div>
            <Typography variant="caption-medium" color="type-color-secondary">
              <FormattedMessage {...st['shared.enterprise-signin.enterprise-sign-in']} />
            </Typography>

            <Typography variant="body-medium">
              <FormattedMessage {...st[showEditLink ? 'shared.enabled' : 'shared.disabled']} />
            </Typography>
          </div>
        </BoxedContainer>
      </div>
      <ResponsiveDialog
        title={<FormattedMessage {...t['social-accounts.social-accounts']} />}
        closable
        open={modalIsOpen}
        onClosed={closeModal}
      >
        <Typography variant="body-medium" className={classes["signInMethodsubHeading"]}>
          <FormattedMessage {...t['social-accounts.info-text_line']} />
        </Typography>
        <div>
          <Typography variant="caption-medium-strong" className={classes["otherWaysSignInText"]}>
            <FormattedMessage {...t['social-accounts.social-accounts']} />
          </Typography>

          <SocialConnectToLink
            socialLink={lastpassLink}
            isViewOnly={isViewOnly}
            socialEmail={lastpassEmail}
            onDisconnect={disconnectSocialAccount(appconfig.socialAccounts.LASTPASS)}
            icon={<img className={classes["socialLogo"]} src={LastPassLogo} alt="" />}
            connectToText={<FormattedMessage {...st['shared.connect-to-lastpass']} />}
            connectAsText={<FormattedMessage
              {...t['social-accounts.connected-as']}
              values={{
                br: <br />,
                b: (chunks) => <b>{chunks}</b>,
                socialId: lastpassEmail }} />}
          />
          <SocialConnectToLink
            socialLink={googleLink}
            isViewOnly={isViewOnly}
            socialEmail={googleEmail}
            onDisconnect={disconnectSocialAccount(appconfig.socialAccounts.GOOGLE)}
            icon={<GoogleIcon size="24px" />}
            connectToText={<FormattedMessage {...st['shared.connect-to-google']} />}
            connectAsText={<FormattedMessage
              {...t['social-accounts.connected-as']}
              values={{
                br: <br />,
                b: (chunks) => <b>{chunks}</b>,
                socialId: googleEmail }} />}
          />
          <SocialConnectToLink
            socialLink={facebookLink}
            isViewOnly={isViewOnly}
            socialEmail={facebookEmail}
            onDisconnect={disconnectSocialAccount(appconfig.socialAccounts.FACEBOOK)}
            icon={<FacebookIcon size="24px" />}
            connectToText={<FormattedMessage {...st['shared.connect-to-facebook']} />}
            connectAsText={<FormattedMessage
              {...t['social-accounts.connected-as']}
              values={{
                br: <br />,
                b: (chunks) => <b>{chunks}</b>,
                socialId: facebookEmail }} />}
          />
          <SocialConnectToLink
            socialLink={linkedinLink}
            isViewOnly={isViewOnly}
            disableNewConnect
            socialEmail={linkedinEmail}
            onDisconnect={disconnectSocialAccount(appconfig.socialAccounts.LINKEDIN)}
            icon={<LinkedinIcon size="24px" />}
            connectToText={<FormattedMessage {...st['shared.connect-to-linkedin']} />}
            connectAsText={<FormattedMessage
              {...t['social-accounts.connected-as']}
              values={{
                br: <br />,
                b: (chunks) => <b>{chunks}</b>,
                socialId: linkedinEmail }} />}
          />
          <SocialConnectToLink
            socialLink={microsoftLink}
            isViewOnly={isViewOnly}
            socialEmail={microsoftEmail}
            onDisconnect={disconnectSocialAccount(appconfig.socialAccounts.MICROSOFT)}
            icon={<MicrosoftIcon size="24px" />}
            connectToText={<FormattedMessage {...st['shared.connect-to-microsoft']} />}
            connectAsText={<FormattedMessage
              {...t['social-accounts.connected-as']}
              values={{
                br: <br />,
                b: (chunks) => <b>{chunks}</b>,
                socialId: microsoftEmail }} />}
          />
          <SocialConnectToLink
            socialLink={appleLink}
            isViewOnly={isViewOnly}
            socialEmail={appleEmail}
            onDisconnect={disconnectSocialAccount(appconfig.socialAccounts.APPLE)}
            icon={<AppleIcon size="24px" />}
            connectToText={<FormattedMessage {...st['shared.connect-to-apple']} />}
            connectAsText={<FormattedMessage
              {...t['social-accounts.connected-as']}
              values={{
                br: <br />,
                b: (chunks) => <b>{chunks}</b>,
                socialId: appleEmail }} />}
          />
        </div>
        <EnterpriseSignIn />
      </ResponsiveDialog>
    </div>
  );
};

SocialAccounts.propTypes = {
  googleEmail: PropTypes.string,
  googleLink: PropTypes.string,
  facebookEmail: PropTypes.string,
  facebookLink: PropTypes.string,
  linkedinEmail: PropTypes.string,
  linkedinLink: PropTypes.string,
  microsoftEmail: PropTypes.string,
  microsoftLink: PropTypes.string,
  lastpassEmail: PropTypes.string,
  lastpassLink: PropTypes.string,
  appleEmail: PropTypes.string,
  appleLink: PropTypes.string,
  disconnectSocialAccount: PropTypes.func.isRequired,
  isViewOnly: PropTypes.bool,
  showEditLink: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  editLinkClick: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired

};

export default SocialAccounts;
