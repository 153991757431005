import React from "react";
import PropTypes from "prop-types";
import {
  FormattedMessage,
  defineMessages,
  useIntl,
} from "react-intl";
import st from "core/shared-translations";
import classes from "./signin-security.scss";
import BoxedContainer from "components/boxed-container-view";
import { EditOutlinedIcon, InfoOutlinedIcon, PasskeyOutlinedIcon } from "@getgo/chameleon-icons/react";
import ResponsiveDialog from 'components/dialog-view.js';
import { AlertV2, Typography, Button, Skeleton } from "@getgo/chameleon-web-react-wrapper";
import commonClasses from "styles/_base.scss";
import PasskeyDetail from "components/passkey-detail";
import PasskeyVerification from "../containers/passkey-verification-container";

const t = defineMessages({
  "passkey-signin.title": {
    id: "passkey-signin.title",
    defaultMessage: "Passkey sign-in",
    description: "Title string for the passkey editor section",
  },
  "passkey-signin.description": {
    id: "passkey-signin.description",
    defaultMessage: "Passkeys are stronger than traditional passwords. With it, you can sign in to GoTo using your device lock, like fingerprint, PIN, or face scan. <a>Learn more</a>",
    description: "Basic description for passkey module with an learn more link",
  },
  "passkey-signin.create.passkey": {
    id: "passkey-signin.create.passkey",
    defaultMessage: "Create a passkey",
    description: "create passkey text",
  },
  "passkey-signin.manage.passkey.subheading": {
    id: "passkey-signin.manage.passkey.subheading",
    defaultMessage: "Manage your passkeys",
    description: "Manage passkey subheading",
  },
  "passkey-signin.about.description": {
    id: "passkey-signin.about.description",
    defaultMessage: "With passkeys you can use your device lock, like fingerprint, PIN, or face scan, to sign in to GoTo.",
    description: "Description  about passkey",
  },
  "passkey-signin.add.another.passkey": {
    id: "passkey-signin.add.another.passkey",
    defaultMessage: "Add another passkey",
    description: "Button text for adding another passkey",
  },
});

const PasskeySignin = ({
  createNewPasskey,
  passkeysList,
  modalIsOpen,
  onEditModalClick,
  closeModal,
  isLoading,
  onDelete,
  passkeyCreationInProgress,
  verificationModalIsOpen,
  completeProcess,
  sendVerificationEmail,
  credentialIdForDeletion
}) => {
  const intl = useIntl();
  return (
    <div>
      <BoxedContainer
        title={<FormattedMessage {...t["passkey-signin.title"]} />}
        onClick={onEditModalClick}
        showCTAButton={!!passkeysList.length}
        boxIcon={<PasskeyOutlinedIcon color="#000000" />}
        isLoading={isLoading}
        buttonIcon={<EditOutlinedIcon />}
        buttonText={<FormattedMessage {...st["shared.edit"]} />}
      >
        {!!passkeysList.length &&
          <React.Fragment>
            <Typography variant="caption-medium" color="type-color-secondary">
              <FormattedMessage {...t["passkey-signin.manage.passkey.subheading"]} />
            </Typography>
            {passkeysList.map(
              ({ name, lastUsedTime, i }) => <PasskeyDetail key={`name-${name}`} name={name} lastUsedTime={lastUsedTime} />
            )}
          </React.Fragment>}
        {!passkeysList.length &&
          <div className={classes["enhanceSecurityConatiner"]}>
            <AlertV2
              icon={<InfoOutlinedIcon color="var(--goto-icon-color-brand-default)" />}
              className={commonClasses["info-state"]}
            >
              <Typography>
                <FormattedMessage
                  {...t['passkey-signin.description']}
                  values={{
                    a : (chunks) => <a className="learnMoreLink" href="https://support.goto.com/connect/help/create-and-manage-passkeys-for-sign-in" rel="noopener noreferrer" target="_blank">{chunks}</a>
                  }}
                />
              </Typography>
            </AlertV2>
            {passkeyCreationInProgress && <Skeleton variant="rectangle" />}
            {!passkeyCreationInProgress && <Button
              variant="secondary"
              href="#"
              onClick={!passkeyCreationInProgress && createNewPasskey}
            >
              {intl.formatMessage(t["passkey-signin.create.passkey"])}
            </Button>}
          </div>}
      </BoxedContainer>
      <ResponsiveDialog
        closable
        onClosed={closeModal}
        open={modalIsOpen}
        title={verificationModalIsOpen ?
          intl.formatMessage(st["shared.email-verification"]) :
          <FormattedMessage {...t["passkey-signin.manage.passkey.subheading"]} />}
      >
        {!verificationModalIsOpen && <React.Fragment>
          <div className={classes["passkeyModalHeader"]}>
            <Typography variant="body-medium">
              <FormattedMessage {...t['passkey-signin.about.description']} />
            </Typography>
            {!!passkeysList.length && !isLoading &&
              <Typography variant="caption-medium-strong">
                <FormattedMessage {...t["passkey-signin.manage.passkey.subheading"]} />
              </Typography>}
          </div>
          {isLoading && <Skeleton variant="rectangle" size="xxxlarge" />}
          {!isLoading && <React.Fragment>
            {passkeysList.map(
              ({ name, lastUsedTime, createdTime, credentialId }) =>
                <PasskeyDetail
                  key={credentialId}
                  name={name}
                  lastUsedTime={lastUsedTime}
                  createdTime={createdTime}
                  credentialId={credentialId}
                  onDelete={onDelete}
                  showCreateTime
                />
            )}
          </React.Fragment>}
          {passkeyCreationInProgress && <Skeleton variant="rectangle" />}
          {!passkeyCreationInProgress &&
            <Button
              className={classes["passkeyModalButton"]}
              disabled={passkeyCreationInProgress}
              variant="neutral"
              onClick={!passkeyCreationInProgress && createNewPasskey}>
              <FormattedMessage {...t['passkey-signin.add.another.passkey']} />
            </Button>}
        </React.Fragment>}

        {verificationModalIsOpen &&
          <PasskeyVerification
            completeProcess={completeProcess}
            closeModal={closeModal}
            sendVerificationEmail={sendVerificationEmail}
            credentialIdForDeletion={credentialIdForDeletion}
          />}
      </ResponsiveDialog>
    </div>
  );
};

PasskeySignin.propTypes = {
  createNewPasskey: PropTypes.func.isRequired,
  passkeysList: PropTypes.array.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  onEditModalClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  passkeyCreationInProgress: PropTypes.bool.isRequired,
  verificationModalIsOpen: PropTypes.bool.isRequired,
  completeProcess: PropTypes.func.isRequired,
  sendVerificationEmail: PropTypes.func.isRequired,
  credentialIdForDeletion: PropTypes.string,
};

export default PasskeySignin;
