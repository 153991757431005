import React from 'react';
import PropTypes from 'prop-types';
import {
  defineMessages,
  useIntl,
} from 'react-intl';

export const App = ({children}) => {
  const t = defineMessages({
    'app.document.title': {
      id: 'app.document.title',
      defaultMessage: 'Profile Management Portal',
      description: 'The document title of the application (appears in browser tab)',
    },
  });
  const intl = useIntl();
  document.title = intl.formatMessage(t['app.document.title']);
  return (
    <div>
      {children}
    </div>
  );
};

App.propTypes = {
  children: PropTypes.element.isRequired,
};

export default App;
