/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import CreateTokens from "../components/create-token-view";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import appconfig from "config/appconfig";
import { isEqual } from 'lodash';
import { uiShowToastrMessage, uiShowConfirmDialog } from 'modules/ui-module';
import {
  setHandledErrorCodes,
  removeHandledErrorCodes
} from 'lib/error-message-handling';
import { stopEvent, copyTextToClipboard, getAPIError } from "lib/utility-functions";
import { useParams, useNavigate } from 'react-router-dom';
import { personalAccessTokenPost, personalAccessTokenPatch, getTokenById, personalAccessTokenIsLoading } from "modules/personal-access-token-module";

const getErrorCodesArray = () => {
  return [
    'duplicate.name',
    'pat.limit.exceeded',
  ];
};

export const CreateTokensContainer = () => {
  const params = useParams();
  const navigate = useNavigate();
  const getValuesFromStore = (state) => {
    let token = {};
    let isEditMode = true;
    const { tokenId } = params || '';
    if (tokenId) {
      token = getTokenById(state, tokenId);
    }
    if (isEqual(token, {})) {
      isEditMode = false;
      token = {
        name: '',
        scopes: []
      };
    }
    return {
      token,
      tokenId,
      isEditMode,
      isLoading: personalAccessTokenIsLoading(state),
    };
  };

  const dispatch = useDispatch();
  const externalProps = useSelector(getValuesFromStore);
  const [token, setToken] = useState({ ...externalProps.token });
  const [tokenValue, setTokenValue] = useState('');
  const [permissionsContentsClass, setPermissionsContentsClass] = useState('');
  const [credentialsContentsClass, setCredentialsContentsClass] = useState('hidden');
  const [permissionsButtonClass, setPermissionsButtonClass] = useState('active');
  const [credentialsButtonClass, setCredentialsButtonClass] = useState('');
  const [permissionsList, setPermissionsList] = useState({});
  const [selectedPermissionsControlledByDevPortal, setSelectedPermissionsControlledByDevPortal] = useState([]);
  const [existingPermissionsNotControlledByDevPortal, setExistingPermissionsNotControlledByDevPortal] = useState([]);
  const [errors, setErrors] = useState({});
  const [tokenCopied, setTokenCopied] = useState(false);
  const origState = useRef();
  const storeOrigStateNow = useRef(false);

  const createOriginalState = () => {
    if (!origState.current) {
      origState.current = {
        token: structuredClone(token),
        errors: structuredClone(errors),
        permissionsContentsClass,
        credentialsContentsClass,
        permissionsButtonClass,
        credentialsButtonClass,
        permissionsList: structuredClone(permissionsList),
        existingPermissionsNotControlledByDevPortal,
        selectedPermissionsControlledByDevPortal,
        tokenCopied,
      };
      const defaultScope = appconfig.defaultProfileScope;
      if (origState.current.selectedPermissionsControlledByDevPortal.indexOf(defaultScope) === -1) {
        origState.current.selectedPermissionsControlledByDevPortal.push(defaultScope);
      }
    }
  };

  const setPermissions = () => {
    const permissions = {};
    let tokenScopes = token && token.scopes && token.scopes.length ? [...token.scopes] : [];
    if (tokenScopes.indexOf(appconfig.defaultProfileScope) < 0) {

      tokenScopes.unshift(appconfig.defaultProfileScope);
    }
    const permissionsControlledByDevPortal = [];
    for (let [key, permissionScopes] of Object.entries(appconfig.permissionsList)) {
      permissionScopes = permissionScopes || [];
      permissionsControlledByDevPortal.push(...permissionScopes);
      const selectedScopes = tokenScopes.filter(el => permissionScopes.includes(el));
      permissions[key] = {
        name: key,
        scopes: permissionScopes,
        isGranularView: false,
        hasChildScopes: permissionScopes.length > 1,
        selectedScopes: selectedScopes,
        intermediate: (selectedScopes.length > 1) && (selectedScopes.length < permissionScopes.length),
      };
      if (key.toLowerCase() === 'profile') {
        if (permissionScopes.indexOf(appconfig.defaultProfileScope) < 0) {
          permissions[key].scopes.unshift(appconfig.defaultProfileScope);
        }
        permissions[key].isGranularView = true;
      }
    }
    // identify the selected scopes by dev portal
    const selectedPermissionsControlledByDevPortalValue = getSelectedPermisisonsControlledByDevPortal(permissions);
    // identify and store all permissions (scopes) not controlled by dev portal to include in submission
    const filteredExistingPermissionsNotControlledByDevPortal = tokenScopes.filter(el => !permissionsControlledByDevPortal.includes(el)) || [];
    const existingPermissionsNotControlledByDevPortalVal = filteredExistingPermissionsNotControlledByDevPortal.slice();
    const selectedPermissionsControlledByDevPortalVal = selectedPermissionsControlledByDevPortalValue.slice();
    setPermissionsList(permissions);
    setExistingPermissionsNotControlledByDevPortal(existingPermissionsNotControlledByDevPortalVal);
    setSelectedPermissionsControlledByDevPortal(selectedPermissionsControlledByDevPortalVal);
    return true;
  };

  //on change of permissions set orignal state
  useEffect(() => {
    //avoid calling createOriginalState on first render
    if (storeOrigStateNow.current) {
      createOriginalState();
    }
    else {
      storeOrigStateNow.current = true;
    }
  }, [permissionsList, existingPermissionsNotControlledByDevPortal, selectedPermissionsControlledByDevPortal]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (externalProps.tokenId && !token.name) {
      toDeveloperTools();
    }
    setPermissions();
  }, []);


  const displayToastrMessage = (toastrType, toastrMessageId, toastrParams = null) => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId, toastrParams));
  };

  const handleXHRResult = (success, messageId, toastrParams = null) => {
    const toastrType = success ? 'success' : 'error';
    displayToastrMessage(toastrType, messageId, toastrParams);
  };

  const validateUserInput = (field, value, onBlurCheck) => {
    let errorText = '';
    const foundErrors = { ...errors };

    if (foundErrors[field]) {
      delete foundErrors[field];
    }
    if (field === 'name') {
      if (value.length > 30) {
        errorText = 'create-token.validation.name-exceeds-max-length';
      }
      if (onBlurCheck && !value.length) {
        errorText = 'create-token.validation.name-required';
      }
    }
    if (errorText.length) {
      foundErrors[field] = errorText;
    }
    setErrors(foundErrors);
    return !errorText.length;
  };

  const updateTokenState = (e) => {
    const foundToken = { ...token };
    const field = e.target.id.split('-')[0];
    const value = e.target.value;
    foundToken[field] = value;
    if (validateUserInput(field, value, false)) {
      setToken(foundToken);
    }
    return true;
  };

  const onCancel = () => {
    if (contentsHaveChanged()) {
      dispatch(uiShowConfirmDialog("hasEdits", () => true, toDeveloperTools));
    } else {
      toDeveloperTools();
    }
    return true;
  };

  const toDeveloperTools = () => {
    navigate("/developer-tools");
  };

  const contentsHaveChanged = () => {

    if (origState && origState.current &&
      origState.current.token && token &&
      origState.current.selectedPermissionsControlledByDevPortal && selectedPermissionsControlledByDevPortal) {

      return !!token.name.length && !isEqual(origState.current.token.name, token.name.trim()) ||
        !isEqual(origState.current.selectedPermissionsControlledByDevPortal?.sort(), selectedPermissionsControlledByDevPortal?.sort());
    }
    return false;
  };

  const getSelectedPermisisonsControlledByDevPortal = (permissionsList) => {
    const selectedPermissionsControlledByDevPortalVal = [];
    for (let [key] of Object.entries(permissionsList)) {
      const selectedScopes = permissionsList[key].selectedScopes || [];
      if (selectedScopes.length)
        selectedPermissionsControlledByDevPortalVal.push(...selectedScopes);
    }
    return selectedPermissionsControlledByDevPortalVal.slice();
  };

  const permissionCheckboxClick = (name, scope) => (e) => {
    const isChecked = e.target && e.target.checked;
    const permissionsListVal = { ...permissionsList };
    if (permissionsListVal && permissionsListVal[name]) {
      const indexOfScope = permissionsListVal[name].selectedScopes.indexOf(scope);
      if (isChecked && indexOfScope < 0) {
        permissionsListVal[name].selectedScopes.push(scope);
      } else if (!isChecked && indexOfScope > -1) {
        permissionsListVal[name].selectedScopes.splice(indexOfScope, 1);
      }
    }
    const selectedPermissionsControlledByDevPortalVal = getSelectedPermisisonsControlledByDevPortal(permissionsListVal);
    setPermissionsList(permissionsListVal);
    setSelectedPermissionsControlledByDevPortal(selectedPermissionsControlledByDevPortalVal);
    return true;
  };

  const permissionGroupCheckboxClick = (name) => (e) => {
    const isChecked = e.target && e.target.checked;
    const permissionsListVal = { ...permissionsList };
    if (permissionsListVal && permissionsListVal[name]) {
      if (isChecked) {
        permissionsListVal[name].selectedScopes = [...permissionsListVal[name].scopes];
      } else {
        permissionsListVal[name].selectedScopes = [];
      }
    }
    const selectedPermissionsControlledByDevPortalVal = getSelectedPermisisonsControlledByDevPortal(permissionsListVal);
    setPermissionsList(permissionsListVal);
    setSelectedPermissionsControlledByDevPortal(selectedPermissionsControlledByDevPortalVal);
    return true;
  };

  const togglePermissionsDetail = (name) => (e) => {
    stopEvent(e);
    const permissionsListVal = { ...permissionsList };
    if (permissionsListVal && permissionsListVal[name]) {
      permissionsListVal[name].isGranularView = !permissionsListVal[name].isGranularView;
    }
    setPermissionsList(permissionsListVal);
    return false;
  };

  const validateEntryOnBlur = (e) => {
    const foundToken = { ...token };
    const field = e.target.id.split('-')[0];
    const value = e.target.value.trim();
    foundToken[field] = value;
    if (validateUserInput(field, value, true)) {
      setToken(foundToken);
    }
    return true;
  };

  const navToCredentialsTab = () => {
    setPermissionsContentsClass('hidden');
    setCredentialsContentsClass('');
    setPermissionsButtonClass('completed');
    setCredentialsButtonClass('completed');
  };

  const onCopy = (str) => (e) => {
    stopEvent(e);
    if (copyTextToClipboard(str)) {
      displayToastrMessage('success', 'copy-to-clipboard.token-id-copied');
    }
    return false;
  };

  const onConfirmTokenCopy = (e) => {
    const isChecked = e.target && e.target.checked;
    setTokenCopied(isChecked);
    return true;
  };

  const enableSubmit = () => {
    return (
      Object.keys(errors).length === 0 &&
      contentsHaveChanged() &&
      !externalProps.isLoading
    );
  };

  const onSave = (e) => {
    stopEvent(e);
    const dataToSave = {};
    if (token.name !== origState.current.token.name) {
      dataToSave.name = token.name;
    }
    if (!isEqual(origState.current.selectedPermissionsControlledByDevPortal, selectedPermissionsControlledByDevPortal)) {
      dataToSave.scopes = [...selectedPermissionsControlledByDevPortal, ...existingPermissionsNotControlledByDevPortal];
      const identityReadScopeIndex = dataToSave.scopes.indexOf(appconfig.defaultProfileScope);
      if (identityReadScopeIndex > -1) {
        dataToSave.scopes.splice(identityReadScopeIndex, 1);
      }
    }
    if (externalProps.isEditMode) {
      setHandledErrorCodes(getErrorCodesArray());
      return dispatch(personalAccessTokenPatch(externalProps.tokenId, dataToSave))
        .then(
          () => {
            handleXHRResult(true, 'token-edit.token-update-successful', {
              name: token.name,
            });
            toDeveloperTools();
          },
          (error) => {
            removeHandledErrorCodes(getErrorCodesArray());
            const errorObj = getAPIError(error);
            const errorCode = errorObj && errorObj.length === 1 ? errorObj[0].code : '';
            if (errorCode === 'duplicate.name') {
              handleXHRResult(false, 'token-edit.token-update-failed-dup-name');
            } else {
              handleXHRResult(false, 'token-edit.token-update-failed');
            }
          },
        );
    }
    else {
      setHandledErrorCodes(getErrorCodesArray());
      dispatch(personalAccessTokenPost(dataToSave))
        .then(
          (response) => {
            setTokenValue(response.payload.token);
            handleXHRResult(true, 'token-edit.token-add-successful');
            navToCredentialsTab();
          },
          (error) => {
            removeHandledErrorCodes(getErrorCodesArray());
            const errorObj = getAPIError(error);
            const errorCode = errorObj && errorObj.length === 1 ? errorObj[0].code : '';
            if (errorCode === 'duplicate.name') {
              handleXHRResult(false, 'token-edit.token-add-failed-dup-name');
            }
            else if (errorCode === 'pat.limit.exceeded') {
              handleXHRResult(false, 'token-edit.token-add-limit');
              toDeveloperTools();
            }
            else {
              handleXHRResult(false, 'token-edit.token-add-failed');
            }
          }
        );
    }
  };



  return (
    <CreateTokens
      tokenValue={tokenValue}
      isEditMode={externalProps.isEditMode}
      errors={errors}
      token={token}
      onChange={updateTokenState}
      onBlur={validateEntryOnBlur}
      permissionsList={permissionsList}
      permissionsButtonClass={permissionsButtonClass}
      credentialsButtonClass={credentialsButtonClass}
      permissionsContentsClass={permissionsContentsClass}
      credentialsContentsClass={credentialsContentsClass}
      permissionCheckboxClick={permissionCheckboxClick}
      permissionGroupCheckboxClick={permissionGroupCheckboxClick}
      togglePermissionsDetail={togglePermissionsDetail}
      onCopy={onCopy}
      onSave={onSave}
      isDone={tokenCopied}
      onConfirmTokenCopy={onConfirmTokenCopy}
      onCancel={onCancel}
      enableCTAs={enableSubmit()}
    />
  );
};


export default CreateTokensContainer;
