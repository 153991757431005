/* eslint-env jquery */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AvatarSelector from "../components/avatar-selector-view";
import {
  meId,
  meData,
  meIsLoading,
  meAvatarLocationUrls,
  meAvatarUrlsRequestGet,
  meAvatarImageSet,
  meNewAvatarImageSource,
  meIsConflicted,
} from "modules/me-module";
import environment from "lib/environment";
import DefaultAvatarImage from "static/images/default.jpg";
import { uiShowToastrMessage } from "modules/ui-module";
import { stopEvent } from "lib/utility-functions";
// Safari does not support HTMLCanvasElement.toBlob() at this time, so import a polyfill to handle the conversion.
if (!HTMLCanvasElement.prototype.toBlob) {
  // Source: https://github.com/blueimp/JavaScript-Canvas-to-Blob
  require("lib/3rdparty-es5/canvas-to-blob.min.js"); // eslint-disable-line
}

export const AvatarSelectorContainer = () => {
  const {
    personData,
    isLoading,
    isViewOnly,
    avatarImageUrl,
    avatarUpdateUrl,
    avatarDeleteUrl,
    hasCustomAvatarImage,
  } = useSelector(getValuesFromStore);
  const dispatch = useDispatch();
  let [allValues, setAllValues] = useState({
    personData: Object.assign({}, personData),
    errors: {},
    isDirty: false,
    modalIsOpen: false,
    isDropZoneDraggedOver: false,
    userImageUrl: "",
    hasDefaultAvatarImage: false,
    toastrType: "",
    toastrMessageId: "",
    dropBoxClass: "",
    imageUploadSelectClass: "",
    imageEditAndSubmitClass: "hidden",
  });
  useEffect(() => {
    dispatch(meAvatarUrlsRequestGet());
    if (!hasCustomAvatarImage) {
      fetch(`${avatarImageUrl}`, { method: 'HEAD' })
        .then(response => {
          const xAmzMetaType = response.headers.get("x-amz-meta-type");
          if (xAmzMetaType !== "undefined" && xAmzMetaType === "default") {
            setAllValues((prevState) => ({
              ...prevState,
              hasDefaultAvatarImage: true,
            }));
          }
        })
        .catch(error => {
          console.error('Error fetching avatar image:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = (e) => {
    stopEvent(e);
    setAllValues((prevState) => ({
      ...prevState,
      modalIsOpen: true,
    }));
  };

  const closeModal = (e) => {
    stopEvent(e);
    setAllValues((prevState) => ({
      ...prevState,
      modalIsOpen: false,
    }));
    displayImageEditor(false);
  };

  const fileDragHover = (e) => {
    stopEvent(e);
    const dropBoxClass = e.type === "dragover" ? " hover" : "";
    setAllValues((prevState) => ({
      ...prevState,
      dropBoxClass,
    }));
  };

  const displayImageEditor = (showImageEditor) => {
    const imageUploadSelectClass = showImageEditor ? "hidden" : "";
    const imageEditAndSubmitClass = showImageEditor ? "" : "hidden";
    setAllValues((prevState) => ({
      ...prevState,
      imageUploadSelectClass,
      imageEditAndSubmitClass,
    }));
  };

  const fileSelectHandler = (e) => {
    // cancel event and hover styling
    fileDragHover(e);

    // fetch FileList object
    const files = e.target.files || e.dataTransfer.files;

    // select the first file as multiple items may be dragged into the drop zone
    let file = null;
    if (files.length) {
      file = files[0];
    }

    const urlReader = new FileReader();

    urlReader.onload = (ev) => {
      displayImageEditor(true);
      setAllValues((prevState) => ({
        ...prevState,
        userImageUrl: ev.target.result,
      }));
    };

    if (file) {
      urlReader.readAsDataURL(file);
    }
  };

  const showImageSelectorControl = (e) => {
    stopEvent(e);
    displayImageEditor(false);
    fileSelectorClick();

    setAllValues((prevState) => ({
      ...prevState,
      userImageUrl: "",
    }));
  };

  const fileSelectorClick = () => {
    const fileSelector = document.getElementById("fileSelector");
    if (fileSelector) {
      fileSelector.click();
    }
  };

  const handleAjaxResult = (success, isUpload) => {
    const toastrType = success ? "success" : "error";
    const toastrMessageId = success
      ? `avatar-selector.${isUpload
        ? "avatar-upload-successful"
        : "reset-to-default-photo_successful"
      }`
      : `avatar-selector.${isUpload ? "avatar-upload-failed" : "reset-to-default-photo_failed"
      }`;
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId));
  };

  const resetToDefaultAvatar = (event) => {
    event.preventDefault();
    event.stopPropagation();

    fetch(`${avatarDeleteUrl}`, { method: 'DELETE' })
      .then(response => {
        if (response.status === 204) {
          handleAjaxResult(true, false);
          dispatch(meAvatarImageSet(DefaultAvatarImage));
          setAllValues((prevState) => ({
          ...prevState,
          hasDefaultAvatarImage: true,
          }));
        } else {
          handleAjaxResult(false, false);
        }
        closeModal();
      })
      .catch(error => {
        console.error('Error deleting avatar image:', error);
        handleAjaxResult(false, false);
        closeModal();
      });
  };

  const processImageAndUpload = (canvasImage) => {

    const originalCanvasImage = canvasImage;
    const maxFileSize = 5 * 1024 * 1024; // 5MB

    const setImageQuality = async (canvas) => {
      let retBlob;

      const getCanvasBlob = (canvas, quality) => {
        return new Promise(function (resolve, reject) {
          canvas.toBlob((blob) => {
            resolve(blob);
          }, 'image/jpeg', parseFloat(quality));
        });
      };

      for (let quality = 0.98; quality >= 0.10; quality = parseFloat(quality - 0.02).toFixed(2)) {
        retBlob = await getCanvasBlob(canvas, quality);
        if (retBlob.size < maxFileSize) {
          return retBlob;
        }
      }
    };

    canvasImage.toBlob(async (blob) => {
      if (blob.size > maxFileSize) {
        blob = await setImageQuality(originalCanvasImage);
      }
      const contentReader = new FileReader();
      contentReader.onload = async (evt) => {
        const imageResult = evt.target.result;

        try {
          const response = await fetch(`${avatarUpdateUrl}`, {
            method: 'PUT',
            body: imageResult,
              });
          if (response.ok) {
            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(blob);
            dispatch(meAvatarImageSet(imageUrl));
            handleAjaxResult(true, true);
            setAllValues((prevState) => ({
              ...prevState,
              hasDefaultAvatarImage: false,
            }));
          } else {
            handleAjaxResult(false, true);
          }
          } catch (error) {
            console.error('Error uploading avatar image:', error);
            handleAjaxResult(false, true);
          } finally {
            closeModal();
          }
      };
      contentReader.readAsArrayBuffer(blob);
    }, "image/jpeg", 1);
  };

  const hasCustomAvatarImageFunc = () =>
    avatarImageUrl.indexOf("blob") > -1 ||
    avatarImageUrl.indexOf("_medium") > -1;

  return (
    <AvatarSelector
      dropBoxClass={allValues.dropBoxClass}
      imageUploadSelectClass={allValues.imageUploadSelectClass}
      imageEditAndSubmitClass={allValues.imageEditAndSubmitClass}
      onAvatarSelectLinkClick={openModal}
      modalIsOpen={allValues.modalIsOpen}
      closeModal={closeModal}
      processImageAndUpload={processImageAndUpload}
      fileDragHover={fileDragHover}
      isDropZoneDraggedOver={allValues.isDropZoneDraggedOver}
      fileSelectHandler={fileSelectHandler}
      avatarImageUrl={avatarImageUrl}
      hasCustomAvatarImage={hasCustomAvatarImageFunc()}
      userImageUrl={allValues.userImageUrl}
      avatarUpdateUrl={avatarUpdateUrl}
      resetToDefaultAvatar={resetToDefaultAvatar}
      hasDefaultAvatarImage={allValues.hasDefaultAvatarImage}
      fileSelectorClick={fileSelectorClick}
      showImageSelectorControl={showImageSelectorControl}
      isViewOnly={isViewOnly}
      userEmail={allValues.personData.email || ""}
      displayName={allValues.personData.displayName || ""}
    />
  );
};
export const getValuesFromStore = (state) => {
  const avatarBaseImageUrl = environment.get().avatar_image_url;
  let avatarImageUrl = `${avatarBaseImageUrl}${meId(state)}_medium.jpg`;
  let hasCustomAvatarImage = false;
  const newAvatarImage = meNewAvatarImageSource(state);
  if (newAvatarImage) {
    hasCustomAvatarImage = true;
    avatarImageUrl = newAvatarImage;
  }

  const avatarLocationUrls = meAvatarLocationUrls(state);
  let avatarUpdateUrl = "";
  let avatarDeleteUrl = "";
  if (avatarLocationUrls) {
    avatarUpdateUrl = avatarLocationUrls.update || "";
    avatarDeleteUrl = avatarLocationUrls.delete || "";
  }

  const mePersonData = meData(state);
  const emails = mePersonData.emails || [];
  const primaryEmail = emails.find((email) => email.type === "primary") || "";
  mePersonData.email = primaryEmail.value;
  // mePersonData.displayName = mePersonData.displayName || "";
  return {
    personData: mePersonData,
    isLoading: meIsLoading(state),
    isViewOnly: meIsConflicted(state),
    avatarImageUrl,
    avatarUpdateUrl,
    avatarDeleteUrl,
    hasCustomAvatarImage,
  };
};

export default AvatarSelectorContainer;
