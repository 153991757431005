import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import classes from './confirm-dialog.scss';
import st from "core/shared-translations";
import dt from "core/dialog-translations";
import { Button, CircularProgress } from '@getgo/chameleon-web-react-wrapper';
import ResponsiveDialog from 'components/dialog-view.js';

const ConfirmDialogView = ({
  textResourceId,
  closeConfirmModalYesFn,
  closeConfirmModalNoFn,
  closeModal,
  modalIsOpen,
  useCountDownTimer = false,
  translationParams = {},
  secondsRemaining = 0,
  variant = "primary"
}) => {
  const intl = useIntl();
  return (
    <ResponsiveDialog
      open={modalIsOpen}
      title={<FormattedMessage {...dt[`confirmDialog.${textResourceId}.title`]} />}
      closable={!useCountDownTimer}
      onClosed={closeModal}
      actions={!useCountDownTimer &&
        <React.Fragment>
          <Button variant={variant} onClick={closeConfirmModalYesFn}>
            <FormattedMessage {...dt[`confirmDialog.${textResourceId}.yesButtonText`]} />
          </Button>
          <Button variant="tertiary" onClick={closeConfirmModalNoFn}>
            <FormattedMessage {...dt[`confirmDialog.${textResourceId}.noButtonText`]} />
          </Button>
        </React.Fragment>}>
      <FormattedMessage
        {...dt[`confirmDialog.${textResourceId}.text`]}
        values={{
          invitingAccountName: translationParams.invitingAccountName,
          name:translationParams.name,
          br : <br />,
          b : (chunks) => <b>{chunks}</b>,
          a : (chunks) => <a href={translationParams.myAccountUrl} aria-label="My Account" target="_self">{chunks}</a>
        }} />
      {useCountDownTimer &&
        <div className={classes["useCountDownTimerBlock"]}>
          <span><CircularProgress className={classes["loadingIcon"]} /></span>
          <FormattedMessage {...dt[`confirmDialog.${textResourceId}.countDownTimerText`]} values={{ seconds: secondsRemaining }} />
        </div>}
    </ResponsiveDialog>
  );
};

ConfirmDialogView.propTypes = {
  textResourceId: PropTypes.string.isRequired,
  closeConfirmModalYesFn: PropTypes.func.isRequired,
  closeConfirmModalNoFn: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  useCountDownTimer: PropTypes.bool,
  translationParams: PropTypes.object.isRequired,
  secondsRemaining: PropTypes.number,
  variant: PropTypes.string
};

export default ConfirmDialogView;
