import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import MainNav from "../components/main-nav-view";
import {
  meId,
  meProducts,
  meIsAccountAdmin,
  meUserName,
  meDisplayName,
  meNewAvatarImageSource,
  meSupportExperience,
  meLocale,
} from "modules/me-module";
import auth from "lib/profile-auth";
import environment from "lib/environment";
import { billingMeRequestGet, meIsBillingAdmin } from "modules/billing-module";
import { mtgServiceSettingsGet, gotoAppEnabled } from "modules/meeting-module";
import appconfig from "config/appconfig";
import { getSupportExperienceUrls, stopEvent } from "lib/utility-functions";
export const MainNavContainer = () => {
  const {
    logout,
    myProducts,
    displayName,
    avatarImageUrl,
    isBillingAdmin,
    isAccountAdmin,
    currentPath,
    displayEmail,
    supportExperience,
    isGotoAppEnabled,
    language,
  } = useSelector(getValuesFromStore);
  const [showMenu, setShowMenu] = React.useState(false);

  const navigate = useNavigate();

  const compareRoutes = () => (route) => {
    return window.location.pathname === route;
  };

  const movetoDifferentPage = (e) => (route) => {
    stopEvent(e);
    navigate(route);
    return;
  };

  const toggleMenuVisibility = () => {
    const displayMenu = !showMenu;
    setShowMenu(displayMenu);
    return;
  };


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(billingMeRequestGet());
    if (myProducts.indexOf("g2m") > -1 || myProducts.indexOf("g2mfree") > -1) {
      dispatch(mtgServiceSettingsGet());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainNav
      logout={logout}
      displayName={displayName}
      meIsAccountAdmin={isAccountAdmin}
      myProducts={myProducts}
      avatarImageUrl={avatarImageUrl}
      meIsBillingAdmin={isBillingAdmin}
      compareRoutes={compareRoutes()}
      showSupportLink={supportExperience !== appconfig.supportExperiences.HIDE}
      supportUrls={getSupportExperienceUrls(supportExperience)}
      gotoAppEnabled={isGotoAppEnabled}
      language={language}
      movetoDifferentPage={movetoDifferentPage()}
      displayEmail={displayEmail}
      showMenu={showMenu}
      toggleMenuVisibility={toggleMenuVisibility}
    />
  );
};
export const getValuesFromStore = (state) => {
  // Adding a default value for environment.get().avatar_image_url,
  //   because during unit testing a false warning message is thrown without it.
  const avatarBaseImageUrl = environment.get().avatar_image_url || "";
  let avatarImageUrl = avatarBaseImageUrl.length
    ? `${avatarBaseImageUrl}${meId(state)}_medium.jpg`
    : "";

  // Check if the user has uploaded a new avatar image during the session.
  //   If he/she did, use the URL of that image as a source for the mini avatar.
  const newAvatarImage = meNewAvatarImageSource(state);
  if (newAvatarImage) {
    avatarImageUrl = newAvatarImage;
  }

  return {
    logout: auth.logout.bind(auth),
    myProducts: meProducts(state),
    displayName: meDisplayName(state) || "",
    avatarImageUrl,
    isBillingAdmin: meIsBillingAdmin(state),
    isAccountAdmin: meIsAccountAdmin(state),
    currentPath: window.location.pathname,
    displayEmail: meUserName(state) || "",
    supportExperience: meSupportExperience(state) || appconfig.supportExperiences.DEFAULT,
    isGotoAppEnabled: gotoAppEnabled(state),
    language: meLocale(state)
      ? meLocale(state).split("_")[0].toLowerCase()
      : "en",
  };
};

export default MainNavContainer;
