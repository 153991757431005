import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, defineMessages } from "react-intl";
import classes from "./password-strength-indicator.scss";
import { Typography } from '@getgo/chameleon-web-react-wrapper';
import { ProtectedOutlinedIcon, ChevronDownOutlinedIcon, ChevronUpOutlinedIcon } from '@getgo/chameleon-icons/react';

const t = defineMessages({
  "reset-password-password-strength-label": {
    id: "reset-password-password-strength-label",
    defaultMessage: "Strength: ",
    description: "Strength text",
  },
  "reset-password-password-strength-label-weak": {
    id: "reset-password-password-strength-label-weak",
    defaultMessage: "Weak",
    description: "Strength Label 1",
  },
  "reset-password-password-strength-label-fair": {
    id: "reset-password-password-strength-label-fair",
    defaultMessage: "Fair",
    description: "Strength Label 2",
  },
  "reset-password-password-strength-label-good": {
    id: "reset-password-password-strength-label-good",
    defaultMessage: "Good",
    description: "Strength Label 3",
  },
  "reset-password-password-strength-label-strong": {
    id: "reset-password-password-strength-label-strong",
    defaultMessage: "Strong",
    description: "Strength Label 1",
  },
  "reset-password-strong-password-tips": {
    id: "reset-password-strong-password-tips",
    defaultMessage: "Tips for setting a strong password",
    description: "Tips section text",
  },
  "reset-password-strong-password-tips-first": {
    id: "reset-password-strong-password-tips-first",
    defaultMessage:
      "Use a mix of capital and lowercase letters, numbers, and symbols.",
    description: "Tip text 1",
  },
  "reset-password-strong-password-tips-second": {
    id: "reset-password-strong-password-tips-second",
    defaultMessage: "Avoid common, easy-to-guess words.",
    description: "Tip text 2",
  },
  "reset-password-strong-password-tips-third": {
    id: "reset-password-strong-password-tips-third",
    defaultMessage: "Use a long phrase, like a movie quote.",
    description: "Tip text 3",
  },
  "reset-password-password-strength-placeholder": {
    id: "reset-password-password-strength-placeholder",
    defaultMessage: "Start typing your password",
    description: "Reset password Placeholder text",
  },
  "reset-password-editor.weak-password": {
    id: "reset-password-editor.weak-password",
    defaultMessage: "You have to set at least a Good password!",
    description: "Content of the error message when the New password is below good strength",
  },
});

let passwordStrengthIndicator = {
  0: {
    text: "reset-password-password-strength-label-weak",
    color: "type-color-danger",
    hexColor: "#d33a61"
  },
  1: {
    text: "reset-password-password-strength-label-weak",
    color: "type-color-danger",
    hexColor: "#d33a61"
  },
  2: {
    text: "reset-password-password-strength-label-fair",
    color: "yellow-500",
    hexColor: "#a5941e"
  },
  3: {
    text: "reset-password-password-strength-label-good",
    color: "type-color-success",
    hexColor: "#1b8756"
  },
  4: {
    text: "reset-password-password-strength-label-strong",
    color: "type-color-success",
    hexColor: "#1b8756"
  },
};

/* eslint-disable */
const PasswordStrengthIndicator = ({ passwordStrengthScore, showWeakPasswordWarning }) => {
  const [toggle, setToggle] = useState(false);
  let getColor = (i) => {
    if (passwordStrengthScore >= 0) {
      if (i == 0 && passwordStrengthScore == 0) {
        return passwordStrengthIndicator[passwordStrengthScore].hexColor;
      } else if (i + 1 <= passwordStrengthScore) {
        return passwordStrengthIndicator[passwordStrengthScore].hexColor;
      }
    }
  };
  return (
    <>
      <div className={classes["passwordStrengthIndicatorDiv"]}>
        {[...Array(4).keys()].map((i) => {
          return (
            <div
              style={{
                background: getColor(i),
              }}
              key={`strength-block-${i}`}
              className={classes["strength"]}
            ></div>
          );
        })}
      </div>

      {passwordStrengthIndicator[passwordStrengthScore] ? (
        <Typography variant="body-small-strong" color={passwordStrengthIndicator[passwordStrengthScore].color}>
          <FormattedMessage
            {...t[passwordStrengthIndicator[passwordStrengthScore].text]}
          />
        </Typography>
      ) : (
        <Typography variant="body-small" color="type-color-secondary">
          &nbsp;
          <FormattedMessage
            {...t["reset-password-password-strength-placeholder"]}
          />
        </Typography>
      )}
      {showWeakPasswordWarning &&
        <Typography variant="body-small" color="type-color-danger">
          <FormattedMessage {...t["reset-password-editor.weak-password"]} />
        </Typography>}
      <Typography variant="body-small" color="type-color-brand-default" onClick={() => setToggle(!toggle)}>
        <span className={classes["tipsInfoText"]}>
          <ProtectedOutlinedIcon color="type-color-brand-defaulted" />
          &nbsp;
          <FormattedMessage {...t["reset-password-strong-password-tips"]} />
          &nbsp;
          {toggle ?
            <ChevronUpOutlinedIcon color="type-color-brand-default" /> :
            <ChevronDownOutlinedIcon color="type-color-brand-default" />
          }
        </span>
      </Typography>
      {toggle && (
        <div className={`${classes["strongPasswordCollapsibleText"]} `}>
          <ul className={classes["tipsList"]}>
            <li>
              <Typography variant="caption-medium">
                <FormattedMessage
                  {...t["reset-password-strong-password-tips-first"]}
                />
              </Typography>
            </li>
            <li>
              <Typography variant="caption-medium">
                <FormattedMessage
                  {...t["reset-password-strong-password-tips-second"]}
                />
              </Typography>
            </li>
            <li>
              <Typography variant="caption-medium">
                <FormattedMessage
                  {...t["reset-password-strong-password-tips-third"]}
                />
              </Typography>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

PasswordStrengthIndicator.propTypes = {
  passwordStrengthScore: PropTypes.number.isRequired,
};

export default PasswordStrengthIndicator;
