import React from "react";
import PropTypes from "prop-types";
import AvatarEditorContainer from "../containers/avatar-editor-container";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import classes from "./personal-info.scss";
import commonClasses from "styles/_base.scss";
import st from "core/shared-translations";
import BoxedContainer from "components/boxed-container-view";
import { EditOutlinedIcon, CloudUploadOutlinedIcon, EditFilledIcon } from "@getgo/chameleon-icons/react";
import { AvatarV2, Typography } from "@getgo/chameleon-web-react-wrapper";
import ResponsiveDialog from 'components/dialog-view.js';

const t = defineMessages({
  "avatar-selector.change-profile-photo": {
    id: "avatar-selector.change-profile-photo",
    defaultMessage: "Change your profile picture",
    description: "Modal dialog title for changing the profile photo",
  },
  "avatar-selector.drag-drop-picture-here": {
    id: "avatar-selector.drag-drop-picture-here",
    defaultMessage: "Drop photo here",
    description: "Drag and drop zone instruction line 1",
  },
  "avatar-selector.drag-drop-select-an-image": {
    id: "avatar-selector.drag-drop-select-an-image",
    defaultMessage: "choose a photo",
    description:
      "Drag and drop zone instruction line 2 phrase: select an image",
  },
  "avatar-selector.drag-drop-image-formats-accepted": {
    id: "avatar-selector.drag-drop-image-formats-accepted",
    defaultMessage:
      "For best results, use a .png or .jpg file smaller than 2 MB.",
    description:
      "Drag and drop zone instruction line 3 - image formats accepted",
  },
  "avatar-selector.reset-to-default-photo": {
    id: "avatar-selector.reset-to-default-photo",
    defaultMessage: "Reset to default photo",
    description: "Modal dialog action link to reset user's default photo",
  },
  "avatar-selector.avatar-titletext-custom": {
    id: "avatar-selector.avatar-titletext-custom",
    defaultMessage: "Custom avatar image",
    description:
      "Title (hover) text to display on mouseover of avatar image, when a custom image is loaded",
  },
  "avatar-selector.avatar-titletext-default": {
    id: "avatar-selector.avatar-titletext-default",
    defaultMessage: "Default avatar image",
    description:
      "Title (hover) text to display on mouseover of avatar image, when a generic image is loaded",
  },
});

const AvatarSelector = ({
  dropBoxClass,
  imageUploadSelectClass,
  imageEditAndSubmitClass,
  onAvatarSelectLinkClick,
  modalIsOpen,
  closeModal,
  processImageAndUpload,
  fileDragHover,
  fileSelectHandler,
  avatarImageUrl,
  userImageUrl,
  avatarUpdateUrl,
  resetToDefaultAvatar,
  hasDefaultAvatarImage,
  fileSelectorClick,
  showImageSelectorControl,
  isViewOnly,
  userEmail,
  displayName
}) => {
  const intl = useIntl();
  return (
    <div id="avatar-selection-view">
      <div className={classes["fluidContainer"]}>
        <BoxedContainer
          title={<FormattedMessage {...st["shared.profile.picture"]} />}
          buttonIcon={<EditOutlinedIcon />}
          buttonText={<FormattedMessage {...st["shared.edit"]} />}
          disabled={isViewOnly}
          onClick={onAvatarSelectLinkClick}
        >
          <AvatarV2
            size="xxlarge"
            title={intl.formatMessage(
              t[
              hasDefaultAvatarImage
                ? "avatar-selector.avatar-titletext-default"
                : "avatar-selector.avatar-titletext-custom"
              ]
            )}
            label={intl.formatMessage(
              t[
              hasDefaultAvatarImage
                ? "avatar-selector.avatar-titletext-default"
                : "avatar-selector.avatar-titletext-custom"
              ]
            )}
          >
            <img src={avatarImageUrl} alt={displayName} />
          </AvatarV2>
        </BoxedContainer>
      </div>

      <div className={classes["avatarEditorBlock"]}>
        <AvatarV2
          variant="primary"
          size="xxlarge"
          type="button"
          label={
            intl.formatMessage(t[
              hasDefaultAvatarImage
                ? "avatar-selector.avatar-titletext-default"
                : "avatar-selector.avatar-titletext-custom"
            ]
          )
          }
          onClick={onAvatarSelectLinkClick}
        >
          <img src={avatarImageUrl} alt={displayName} />
          <div className={classes["avatarBadge"]}>
            <EditFilledIcon size="17px" />
          </div>
        </AvatarV2>

        <div>
          <Typography variant="heading-large">{displayName}</Typography>
          <Typography variant="body-medium" color="type-color-secondary">{userEmail}</Typography>
        </div>
      </div>

      <ResponsiveDialog
        id="avatarSelectorModal"
        open={modalIsOpen}
        closable
        onClosed={closeModal}
        title={<FormattedMessage {...t["avatar-selector.change-profile-photo"]} />}
        actions={false}
      >
        {!imageUploadSelectClass &&
          <div id="ImageUploadSelect">
            <div
              className={commonClasses[`dropBox${dropBoxClass}`]}
              id="DropBox"
              onDragOver={fileDragHover}
              onDragLeave={fileDragHover}
              onDrop={fileSelectHandler}
              onChange={fileSelectHandler}
            >
              <CloudUploadOutlinedIcon size="48px" color="#0000004D" />
              <div className={classes["dropBoxLine1"]}>
                <Typography variant="body-small">
                  <FormattedMessage {...t["avatar-selector.drag-drop-picture-here"]} />
                </Typography>
              </div>
              <div className={classes["dropBoxLine2"]}>
                <input
                  type="file"
                  data-testid="fileSelector"
                  id="fileSelector"
                  name="fileSelector"
                  className="hidden"
                  accept=".png, .jpg, .jpeg"
                  onChange={fileSelectHandler}
                  style={{ display: "none" }}
                />
                <Typography variant="body-small">
                  <span>{`${intl.formatMessage(st["shared.or"])} `}</span>
                  <a href="#" id="choosePhotoLink" onClick={fileSelectorClick} className={classes["pictureSelector"]}>
                    <FormattedMessage {...t["avatar-selector.drag-drop-select-an-image"]} />
                  </a>
                </Typography>
              </div>
              <div className={classes["dropBoxLine3"]}>
                <Typography variant="body-small" color="type-color-secondary">
                  <FormattedMessage {...t["avatar-selector.drag-drop-image-formats-accepted"]} />
                </Typography>
              </div>
            </div>
            <div className={classes["modalResetPhotoLink"]}>
              {!hasDefaultAvatarImage && (
                <Typography variant="body-small">
                  <a
                    href="#"
                    id="resetToDefaultPhoto"
                    onClick={resetToDefaultAvatar}
                    className={`${classes["resetToDefaultText"]} ${classes["pictureSelector"]}`}
                  >
                    <FormattedMessage {...t["avatar-selector.reset-to-default-photo"]} />
                  </a>
                </Typography>

              )}
            </div>
          </div>}
        {!imageEditAndSubmitClass &&
          <div id="ImageEditAndSubmit">
            <AvatarEditorContainer
              userImageUrl={userImageUrl}
              avatarUpdateUrl={avatarUpdateUrl}
              closeModal={closeModal}
              processImageAndUpload={processImageAndUpload}
              showImageSelectorControl={showImageSelectorControl}
            />
          </div>}
      </ResponsiveDialog>
    </div>
  );
};

AvatarSelector.propTypes = {
  avatarImageUrl: PropTypes.string.isRequired,
  avatarUpdateUrl: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  dropBoxClass: PropTypes.string.isRequired,
  fileDragHover: PropTypes.func.isRequired,
  fileSelectHandler: PropTypes.func.isRequired,
  fileSelectorClick: PropTypes.func.isRequired,
  hasDefaultAvatarImage: PropTypes.bool.isRequired,
  imageEditAndSubmitClass: PropTypes.string.isRequired,
  imageUploadSelectClass: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  onAvatarSelectLinkClick: PropTypes.func.isRequired,
  processImageAndUpload: PropTypes.func.isRequired,
  resetToDefaultAvatar: PropTypes.func.isRequired,
  showImageSelectorControl: PropTypes.func.isRequired,
  userImageUrl: PropTypes.string,
  isViewOnly: PropTypes.bool,
  userEmail: PropTypes.string,
  displayName: PropTypes.string
};

export default AvatarSelector;
