import React from "react";
import PropTypes from "prop-types";
import { AlertV2 } from "@getgo/chameleon-web-react-wrapper";
import classes from "./alert.scss";
import appconfig from "config/appconfig";

export const Alert = ({ type, icon, body, buttons, handleDismiss, isCloseButton = true }) => {

  return (
    <AlertV2
      data-testid="alert"
      icon={<div />}
      variant={type}
      closable={isCloseButton}
      onClose={handleDismiss}
      className={`${classes.alertBox} ${type === 'danger' ? classes.dangerBackground : ''}`}
    >
      <div className={classes.content}>
        {icon}
        {body}
        {buttons}
      </div>
    </AlertV2>
  );
};

Alert.defaultProps = {
  type: appconfig.alertTypes.WARNING,
  handleDismiss: () => { },
};

Alert.propTypes = {
  type: PropTypes.oneOf(["warning", "danger", "success", "info"]),
  icon: PropTypes.any,
  body: PropTypes.any.isRequired,
  buttons: PropTypes.any,
  handleDismiss: PropTypes.func,
  isCloseButton: PropTypes.bool,
};

export default Alert;
