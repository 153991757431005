import React from "react";
import PropTypes from "prop-types";
import {
  FormattedMessage,
  useIntl
} from "react-intl";
import st from "core/shared-translations";
import { SendOutlinedIcon } from "@getgo/chameleon-icons/react";
import { Typography, Button, InputField } from "@getgo/chameleon-web-react-wrapper";
import classes from './signin-security.scss';


const PasskeyVerification = ({
  userName,
  enableSubmit,
  onChange,
  error,
  onBlur,
  onConfirm,
  verificationCode,
  sendVerificationEmail,
  closeModal,
  isLoading
}) => {
  const intl = useIntl();
  return (
    <div id="passkey-verification-content" className={classes["emailVerificaionContainer"]}>
      <Typography variant="body-medium">
        <FormattedMessage
          {...st['shared.email-verification.user-instruction']}
          values={{
            span: (chunks) => <span className="emailText">{chunks}</span>,
            emailAddress: userName }} />
      </Typography>
      <InputField
        name="verificationCode"
        value={verificationCode}
        onChange={onChange}
        onBlur={onBlur}
        fullwidth
        error={!!error}
        helperText={
          !!error &&
          intl.formatMessage(st[error])
        }
      >
        {intl.formatMessage(st['shared.email-verification.verification-code'])}
      </InputField>
      <Typography variant="body-small">
        <FormattedMessage {...st['shared.email-verification.info-message']} />
      </Typography>
      <Button
        variant="secondary"
        trailingIcon={<SendOutlinedIcon />}
        onClick={sendVerificationEmail}
      >
        <FormattedMessage {...st['shared.email-verification.resend-verification-email-link-text']} />
      </Button>
      <div id="cta" className={classes["ctaSectionConatiner"]}>
        <div className={classes["ctaSection"]}>
          <Button
            id="email-verification-confirm-button"
            disabled={!enableSubmit}
            onClick={onConfirm}
          >
            {isLoading ?
              <FormattedMessage {...st['shared.confirming']} /> :
              <FormattedMessage {...st['shared.confirm']} />}
          </Button>
          <Button
            id="email-verification-back-button"
            onClick={closeModal}
            variant="tertiary"
          >
            <FormattedMessage {...st['shared.back']} />
          </Button>
        </div>
      </div>
    </div>
  );
};

PasskeyVerification.propTypes = {
  userName: PropTypes.string.isRequired,
  enableSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  verificationCode: PropTypes.string.isRequired,
  sendVerificationEmail: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PasskeyVerification;
