import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import SideNav from "../components/side-nav-view";
import { stopEvent } from "lib/utility-functions";

export const SideNavContainer = () => {
  const navigate = useNavigate();

  const compareRoutes = () => (route) => {
    return window.location.pathname === route;
  };

  const movetoDifferentPage = (e) => (route) => {
    stopEvent(e);
    navigate(route);
    return;
  };

  return (
    <SideNav
      compareRoutes={compareRoutes()}
      movetoDifferentPage={movetoDifferentPage()}
    />
  );
};

export default SideNavContainer;
