import React from "react";
import PropTypes from "prop-types";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import MainNav from "core/containers/main-nav-container";
import LogoHeader from "core/containers/logo-header-container";
import classes from "./core-view.scss";
import AlertAuthWarning from "containers/alert-auth-warning-container";
import AlertAuthExpired from "containers/alert-auth-expired-container";
import AlertPasskeyContainer from "containers/alert-passkey-container";
import MessageBanner from "containers/message-banner-container";
import { defineMessages, useIntl } from "react-intl";
import mt from "core/message-translations";
import Unauthorized from "routes/unauthorized/containers/unauthorized-container";
import ErrorView from "routes/error/containers/error-container";
import PersonalInfo from "routes/personal-info/containers/personal-info-container";
import SigninSecurity from "routes/signin-security/containers/signin-security-container";
import DeveloperTools from "routes/developer-tools/containers/developer-tools-container";
import CreateTokens from "routes/developer-tools/containers/create-token-container";
import UserInvitation from "routes/user-invite/containers/user-invitation-container";
import AcceptInvitation from "routes/accept-invitation/containers/accept-invitation-container";
import { isUserInvitationPage } from "lib/utility-functions";
import environment from "lib/environment";
import auth from "lib/profile-auth";
import resolveRoute from "lib/resolve-route";
import ConfirmDialog from "containers/confirm-dialog-container";
import SideNav from "core/containers/side-nav-container";
import Notification from "./notificaton-view";


const t = defineMessages({
  "core.skip.to.main.content": {
    id: "core.skip.to.main.content",
    defaultMessage: "Skip to main content",
    description:
      "Skip link to main content to bypass navigation links for better accessibility",
  },
});

const Core = ({
  alertAuthWarningIsVisible,
  alertAuthExpiredIsVisible,
  messageBannerVisible,
  toastrMessage,
  confirmDialog,
  useStandardNav,
  userHasNoPasskey
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  /* istanbul ignore next */
  if (isUserInvitationPage()) {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.get("id")) {
      window.location.href = `${environment.get().myAccount_url}`;
      return null;
    }
    if (auth.auth && urlParams.get("id")) {
      navigate(
        resolveRoute("acceptInvitation", {
          search: `?id=${urlParams.get("id")}`,
        })
      );
      return null;
    }
  }
  return (
    <div id="app-container">
      {confirmDialog &&
        confirmDialog.yesCallbackFn &&
        confirmDialog.noCallbackFn && <ConfirmDialog />}
      <div className="app-container-inner">
        <div className={classes["skipToMainContent"]}>
          <a href="#mainContent">
            {intl.formatMessage(t["core.skip.to.main.content"])}
          </a>
        </div>
        {userHasNoPasskey && <AlertPasskeyContainer />}
        {alertAuthWarningIsVisible && <AlertAuthWarning />}
        {alertAuthExpiredIsVisible && <AlertAuthExpired />}
        {messageBannerVisible && <MessageBanner />}
        {useStandardNav && <MainNav />}
        {!useStandardNav && <LogoHeader />}
        <div className={classes["fluidAppContainer"]}>
          {useStandardNav && <SideNav />}
          <div className={classes['mainDisplay']}>
            {toastrMessage && toastrMessage.type && toastrMessage.messageId &&
              <div className={classes["notificationContainer"]}>
                <Notification
                  toastrType={toastrMessage.type}
                  toastrMessage={intl.formatMessage(mt[toastrMessage.messageId], toastrMessage.params)}
                  toastrMessageId={toastrMessage.messageId}
                  toastrMessageDescription={toastrMessage.toastrMessageDescription && intl.formatMessage(mt[toastrMessage.toastrMessageDescription])}
                />
              </div>}
            <Routes>
              <Route path="/" element={<Navigate to="/personal-info" replace />} />
              <Route exact path="/personal-info" element={<PersonalInfo />} />
              <Route exact path="/signin-security" element={<SigninSecurity />} />
              <Route
                exact
                path="/developer-tools/tokenedit/:tokenId?"
                element={<CreateTokens />}
              />
              <Route exact path="/developer-tools" element={<DeveloperTools />} />
              <Route exact path="/userinvitation" element={<UserInvitation />} />
              <Route
                exact
                path="/acceptinvitation"
                element={<AcceptInvitation />}
              />
              <Route exact path="/unauthorized" element={<Unauthorized />} />
              <Route exact path="/error" element={<ErrorView />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

Core.propTypes = {
  alertAuthWarningIsVisible: PropTypes.bool,
  alertAuthExpiredIsVisible: PropTypes.bool,
  messageBannerVisible: PropTypes.bool,
  useStandardNav: PropTypes.bool.isRequired,
  toastrMessage: PropTypes.shape({
    type: PropTypes.string,
    messageId: PropTypes.string,
    params: PropTypes.object,
    toastrMessageDescription: PropTypes.string
  }),
  confirmDialog: PropTypes.shape({
    yesCallbackFn: PropTypes.func,
    noCallbackFn: PropTypes.func,
  }),
  userHasNoPasskey: PropTypes.bool.isRequired
};

export default Core;
