import {
  daoDevicesGet,
  daoApproveDevice,
  daoReportDevice,
} from 'dao/devices-dao';
import appconfig from 'config/appconfig';

// ------------------------------------
// Constants
// ------------------------------------
export const DEVICES_GET = 'DEVICES_GET';
export const DEVICES_GET_SUCCESS = 'DEVICES_GET_SUCCESS';
export const DEVICES_GET_FAILURE = 'DEVICES_GET_FAILURE';

export const APPROVEDEVICE_PUT = 'APPROVEDEVICE_PUT';
export const APPROVEDEVICE_PUT_SUCCESS = 'APPROVEDEVICE_PUT_SUCCESS';
export const APPROVEDEVICE_PUT_FAILURE = 'APPROVEDEVICE_PUT_FAILURE';

export const REPORTDEVICE_PUT = 'REPORTDEVICE_PUT';
export const REPORTDEVICE_PUT_SUCCESS = 'REPORTDEVICE_PUT_SUCCESS';
export const REPORTDEVICE_PUT_FAILURE = 'REPORTDEVICE_PUT_FAILURE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  data: {
    devices: [],
    currentDeviceId: '',
  },

  // generic or global properties go here
  isLoading: false,
};

// ------------------------------------
// Selectors
// ------------------------------------
export const devicesIsLoading = (state) => state.devices.isLoading;
// export const devicesData = (state) => state.devices.data.data || [];
export const devicesData = (state) => state.devices.data.devices || [];
export const currentDeviceId = (state) => state.devices.currentDeviceId || '';

export const devicesList = (state) => {
  const deviceData = devicesData(state);
  return deviceData;
};

export const getMapMarkersData = (state) => {
  const deviceData = devicesList(state);
  // To construct marker data, filter out all devices that have no geographic coordinates.
  // For more information on the device object, refer to https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=SOA&title=Device+Management+APIs
  const devicesWithCoordinates = deviceData.filter(device => device &&
    device.location && device.location.latitude && device.location.longitude);
  let markerZIndex = devicesWithCoordinates.length;

  // Some of the device.context property values may not be available.
  // The API is expected send unknown property values as a string: "unknown".
  return devicesWithCoordinates.map(device => {
    const dObj = {};
    dObj.os =  device.os && device.os.name ? device.os.name : '';
    dObj.deviceType = device.hardware && device.hardware.type ? device.hardware.type : '';
    dObj.browser = device.browser && device.browser.name ? device.browser.name : '';
    if (device.trusted) {
      dObj.trustLevel = 3;
    } else {
      dObj.trustLevel = device.accountTakeoverScore >= appconfig.devices.highRiskThreshold ? 1 : 2;
    }
    dObj.lat = device.location.latitude;
    dObj.lon = device.location.longitude;
    dObj.fingerprint = device.fingerprint;
    dObj.zIndex = markerZIndex--;
    return dObj;
  });
};

export const getCurrentDevice = (state) => {
  const deviceData = devicesList(state);
  const currDevice = deviceData.find(device => device.currentDevice === true);
  return currDevice || {};
};

// ------------------------------------
// Actions
// ------------------------------------
export const devicesReceiveGetSuccess = (payload = {}) => ({
  type: DEVICES_GET_SUCCESS,
  payload,
});

export const devicesReceiveGetFailure = (payload = {}) => ({
  type: DEVICES_GET_FAILURE,
  payload,
});

export const devicesRequestGet = (cid) => (dispatch) => {
  dispatch({
    type: DEVICES_GET,
  });

  return daoDevicesGet(cid)
    .then(
      response => dispatch(devicesReceiveGetSuccess(response.data)),
      ex => dispatch(devicesReceiveGetFailure(ex)),
    );
};

export const approveDeviceReceivePutSuccess = (payload = {}) => ({
  type: APPROVEDEVICE_PUT_SUCCESS,
  payload,
});

export const approveDeviceReceivePutFailure = (payload = {}) => ({
  type: APPROVEDEVICE_PUT_FAILURE,
  payload,
});

export const approveDeviceRequestPut = (token = '') => dispatch => {
  dispatch({
    type: APPROVEDEVICE_PUT,
  });
  return daoApproveDevice(token)
    .then(
      response => dispatch(approveDeviceReceivePutSuccess(response.data)),
      ex => dispatch(approveDeviceReceivePutFailure(ex)),
    );
};

export const reportDeviceReceivePutSuccess = (payload = {}) => ({
  type: REPORTDEVICE_PUT_SUCCESS,
  payload,
});

export const reportDeviceReceivePutFailure = (payload = {}) => ({
  type: REPORTDEVICE_PUT_FAILURE,
  payload,
});

export const reportDeviceRequestPut = (token = '') => dispatch => {
  dispatch({
    type: REPORTDEVICE_PUT,
  });
  return daoReportDevice(token)
    .then(
      response => dispatch(reportDeviceReceivePutSuccess(response.data)),
      ex => dispatch(reportDeviceReceivePutFailure(ex)),
    );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [DEVICES_GET]: (state) => ({...state, isLoading: true}),
  [DEVICES_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [DEVICES_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [APPROVEDEVICE_PUT]: (state) => ({...state, isLoading: true}),
  [APPROVEDEVICE_PUT_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [APPROVEDEVICE_PUT_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [REPORTDEVICE_PUT]: (state) => ({...state, isLoading: true}),
  [REPORTDEVICE_PUT_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [REPORTDEVICE_PUT_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const devicesReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default devicesReducer;
