import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classes from "./signin-security.scss";
import BoxedContainer from "components/boxed-container-view";
import { InfoOutlinedIcon, ProtectedOutlinedIcon } from "@getgo/chameleon-icons/react";
import { AlertV2, Typography, ButtonLink, Switch, Button } from "@getgo/chameleon-web-react-wrapper";
import commonClasses from "styles/_base.scss";
import st from "core/shared-translations";
import { EditOutlinedIcon } from "@getgo/chameleon-icons/react";
import ResponsiveDialog from 'components/dialog-view.js';

const t = defineMessages({
  "enhanced-security-mfa.enhanced-security": {
    id: "enhanced-security-mfa.enhanced-security",
    defaultMessage: "Enhanced Security",
    description: "Title string for the enhanced security section",
  },
  "enhanced-security-mfa.enhanced-security.enable": {
    id: "enhanced-security-mfa.enhanced-security.enable",
    defaultMessage: "Enable enhanced security",
    description: "Label string for the enable enhanced security slider control",
  },
  "enhanced-security-mfa.enhanced-security.information": {
    id: "enhanced-security-mfa.enhanced-security.information",
    defaultMessage:
      "When enabled, this will provide an extra layer of security to verify the login by leveraging the paired MFA device.",
    description: "Label string for the enable enhanced security slider control",
  },
  "enhanced-security-mfa.enhanced-security.information1": {
    id: "enhanced-security-mfa.enhanced-security.information1",
    defaultMessage:
      "Add an extra layer of security by setting up a second way to verify your account. <a>Learn more</a>",
    description: "Label string for the enable enhanced security slider control",
  },
  "enhanced-security-mfa.enhanced-security.information2": {
    id: "enhanced-security-mfa.enhanced-security.information2",
    defaultMessage:
      "To manage and use personal access tokens, enhanced security needs to be enabled.",
    description: "Label string for the enable enhanced security slider control",
  },
  "enhanced-security-mfa.multi-factor-authentication": {
    id: "enhanced-security-mfa.multi-factor-authentication",
    defaultMessage: "Multi-factor authentication",
    description: "Title string for the multi-factor authentication section",
  },
  "enhanced-security-mfa.paired-to-device": {
    id: "enhanced-security-mfa.paired-to-device",
    defaultMessage: "Paired to device",
    description:
      "Label for indicating that the user has a paired device set up",
  },
  "enhanced-security-mfa.reset-for-new-device": {
    id: "enhanced-security-mfa.reset-for-new-device",
    defaultMessage: "Reset for a new device",
    description: "Link text for resetting MFA using another device.",
  },
  "enhanced-security-mfa.set-up": {
    id: "enhanced-security-mfa.set-up",
    defaultMessage: "Enable enhanced security",
    description: "Link text for initiating the Enhanced Security option.",
  },
  "enhanced-security-mfa.status-on": {
    id: "enhanced-security-mfa.status-on",
    defaultMessage: "Enhanced security enabled",
    description:
      "Hover text to display over the toggle control when the feature is enabled.",
  },
  "enhanced-security-mfa.status-off": {
    id: "enhanced-security-mfa.status-off",
    defaultMessage: "Enhanced security disabled",
    description:
      "Hover text to display over the toggle control when the feature is disabled.",
  },
  "enhanced-security-mfa.learn-more": {
    id: "enhanced-security-mfa.learn-more",
    defaultMessage: "Learn more",
    description: "Link text to the Multi-Factor Authentication document.",
  },
  "enhanced-security-mfa.enhanced-security.information3": {
    id: "enhanced-security-mfa.enhanced-security.information3",
    defaultMessage: "After turning on Enhanced security the system will automatically sign you out, and you have to sign in again using the MFA method.",
    description: "Label string for the enable enhanced security",
  },
});

export const EnhancedSecurity = ({
  toggleEnhancedSecurity,
  resetDeviceOnClick,
  setUpEnhancedSecurityOnClick,
  meEnhancedSecurity,
  meTfaEnrolled,
  isViewOnly = false,
  modalIsOpen,
  openModal,
  closeModal,
  updateEnhacedSecurity,
  enhancedSecurityVal,
  enableCTA,
  userHasPatTokens = false
}) => {
  const intl = useIntl();
  return (
    <div>
      <BoxedContainer
        title={
          <FormattedMessage {...t["enhanced-security-mfa.enhanced-security"]} />
        }
        buttonText={<FormattedMessage {...st["shared.edit"]} />}
        buttonIcon={<EditOutlinedIcon />}
        showCTAButton={meTfaEnrolled && !isViewOnly}
        onClick={meTfaEnrolled && openModal}
        boxIcon={<ProtectedOutlinedIcon color="#000000" />}
      >
        <div className={classes["enhanceSecurityConatiner"]}>
          <div>
            <Typography variant="body-medium">
              <FormattedMessage {...st[enhancedSecurityVal ? "shared.enabled" : "shared.disabled"]} />
            </Typography>
          </div>

          <AlertV2
            icon={<InfoOutlinedIcon color="var(--goto-icon-color-brand-default)" />}
            className={commonClasses["info-state"]}
          >
            <Typography>
              <FormattedMessage
                {...t['enhanced-security-mfa.enhanced-security.information1']}
                values={{
                  a: (chunks) => <a className="learnMoreLink" href="https://support.logmeininc.com/mfa-user-guide" target="_blank" rel="noreferrer">{chunks}</a>
                }} />
            </Typography>

            {userHasPatTokens &&
              <Typography>
                <FormattedMessage {...t["enhanced-security-mfa.enhanced-security.information2"]} />
              </Typography>}
          </AlertV2>
          {!meTfaEnrolled && !isViewOnly &&
            <ButtonLink
              variant="secondary"
              href="#"
              onClick={setUpEnhancedSecurityOnClick}
            >
              {intl.formatMessage(t["enhanced-security-mfa.set-up"])}
            </ButtonLink>}
          {!!meTfaEnrolled &&
            <div className={classes["mfaSection"]}>
              <Typography variant="heading-small" tag="h2">
                <FormattedMessage {...t["enhanced-security-mfa.multi-factor-authentication"]} />
              </Typography>
              <Typography variant="body-medium" className={classes["pairedDevice"]}>
                <FormattedMessage {...t["enhanced-security-mfa.paired-to-device"]} />
              </Typography>
              <ButtonLink
                variant="secondary"
                disabled={isViewOnly}
                href={resetDeviceOnClick}
              >
                <FormattedMessage {...t["enhanced-security-mfa.reset-for-new-device"]} />
              </ButtonLink>
            </div>}
        </div>
      </BoxedContainer>
      <ResponsiveDialog
        title={<FormattedMessage {...t["enhanced-security-mfa.enhanced-security"]} />}
        open={modalIsOpen}
        closable
        onClosed={closeModal}
        actions={
          <Fragment>
            <Button onClick={toggleEnhancedSecurity} disabled={!enableCTA}>
              <FormattedMessage {...st['shared.save']} />
            </Button>
            <Button onClick={closeModal} variant="tertiary">
              <FormattedMessage {...st["shared.cancel"]} />
            </Button>
          </Fragment>
        }
      >
        <div className={classes["enhanceSecurityConatiner"]}>
          <AlertV2
            icon={<InfoOutlinedIcon color="var(--goto-icon-color-brand-default)" />}
            className={commonClasses["info-state"]}
          >
            <Typography>
              <FormattedMessage
                {...t['enhanced-security-mfa.enhanced-security.information1']}
                values={{
                  a: (chunks) => <a className="learnMoreLink" href="https://support.logmeininc.com/mfa-user-guide" target="_blank" rel="noreferrer">{chunks}</a>
                }} />
            </Typography>
          </AlertV2>
          <div className={classes["enhanceSecurityToggler"]}>
            <Typography variant="caption-medium-strong">
              <FormattedMessage {...t["enhanced-security-mfa.enhanced-security"]} />
            </Typography>
            <Switch
              id="enhancedSecurityToggle"
              labelId="enhanceSecurityToggle"
              name="enhancedSecurityToggle"
              checked={enhancedSecurityVal}
              title={intl.formatMessage(t[`enhanced-security-mfa.status-${meEnhancedSecurity ? "on" : "off"}`])}
              onChange={updateEnhacedSecurity}
              disabled={isViewOnly}
            />
          </div>
          {!meEnhancedSecurity && <Typography variant="body-small">
            <FormattedMessage {...t["enhanced-security-mfa.enhanced-security.information3"]} />
          </Typography>}
        </div>
      </ResponsiveDialog>
    </div>
  );
};

EnhancedSecurity.propTypes = {
  toggleEnhancedSecurity: PropTypes.func.isRequired,
  resetDeviceOnClick: PropTypes.string.isRequired,
  setUpEnhancedSecurityOnClick: PropTypes.func,
  meEnhancedSecurity: PropTypes.bool.isRequired,
  meTfaEnrolled: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
  modalIsOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  updateEnhacedSecurity: PropTypes.func.isRequired,
  enhancedSecurityVal: PropTypes.bool.isRequired,
  enableCTA: PropTypes.bool.isRequired,
  userHasPatTokens: PropTypes.bool.isRequired
};

export default EnhancedSecurity;
