import React from "react";
import PropTypes from "prop-types";
import {
  FormattedMessage,
  defineMessages,
} from "react-intl";
import classes from "./unauthorized-view.scss";
import { Typography, LinearProgress } from '@getgo/chameleon-web-react-wrapper';
import { LaunchOutlinedIcon } from '@getgo/chameleon-icons/react';

export const UnauthorizedView = ({ percent, accountUrl }) => {
  const t = defineMessages({
    "common.forbidden": {
      id: "common.forbidden",
      defaultMessage: "You seem to have landed in the wrong place.",
    },
    "common.contact-admin": {
      id: "common.contact-admin",
      defaultMessage:
        'Please contact the account admin to gain access to this location. We will now redirect you to your <a>account profile</a>.',
    },
  });

  return (
    <div
      id="unauthorized-view"
      className={`text-center ${classes["unauthorized-view"]}`}
    >
      <div className={classes["alignedContent"]}>
        <LaunchOutlinedIcon size="88px" color="#4791ff" />
      </div>
      <div className={classes["mainHeading"]}>
        <Typography variant="heading-medium">
          <FormattedMessage {...t["common.forbidden"]} />
        </Typography>
      </div>
      <div className="row">
        <Typography variant="body-medium">
          <FormattedMessage
            {...t["common.contact-admin"]}
            id="account-link"
            values={{
              a: (chunks) => <a href={accountUrl} id="my-account-link">{chunks}</a>,
            }}
          />
        </Typography>
      </div>
      <LinearProgress value={percent} size="large" />
    </div>
  );
};

UnauthorizedView.propTypes = {
  accountUrl: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
};

export default UnauthorizedView;
