import React from "react";
import PropTypes from "prop-types";
import Alert from "components/alert-view";
import {
  defineMessages,
  FormattedMessage,
} from "react-intl";
import appconfig from "config/appconfig";
import classes from "components/alert.scss";

const messagesConfig = {
  userInvitationError: {
    messageType: appconfig.alertTypes.DANGER,
    showIcon: true,
    iconClass: "togo-icon togo-icon-hand-raise",
    bodyResourceId: "messages.userInvitationError.body",
  },
  userInvitationExpired: {
    messageType: appconfig.alertTypes.DANGER,
    showIcon: true,
    iconClass: "togo-icon togo-icon-hand-raise",
    bodyResourceId: "messages.userInvitationExpired.body",
  },
  userIsConflicted: {
    messageType: appconfig.alertTypes.INFO,
    showIcon: true,
    iconClass: "togo-icon togo-icon-info",
    bodyResourceId: "messages.userIsConflicted.body",
    showButton: true,
    buttonTextId: "messages.userIsConflicted.button",
  }
};

const t = defineMessages({
  "messages.userInvitationError.body": {
    id: "messages.userInvitationError.body",
    defaultMessage: "Hmm, something went wrong. Give it another try.",
  },
  "messages.userInvitationExpired.body": {
    id: "messages.userInvitationExpired.body",
    defaultMessage: "Your invitation has expired",
  },
  "messages.userIsConflicted.body": {
    id: "messages.userIsConflicted.body",
    defaultMessage:
      "Profile settings are currently locked. Please sign out and follow the login flow to update your account.",
  },
  "messages.userIsConflicted.button": {
    id: "messages.userIsConflicted.button",
    defaultMessage: "Sign out",
  },
});
/* eslint-disable */
const MessageBanner = ({ handleAlertDismiss, messageId, params }) => {
  return (
    <Alert
      type={messagesConfig[messageId].messageType || appconfig.alertTypes.INFO}
      icon={
        <i
          className={`${messagesConfig[messageId].iconClass} text-warning ${classes.bannerMessageIcon}`}
        />
      }
      body={
        <span className={classes.bannerMessageText}>
          <FormattedMessage
            {...t[messagesConfig[messageId].bodyResourceId]}
            values={params}
          />
        </span>
      }
      buttons={
        messagesConfig[messageId].showButton && (
          <button
            id="banner-button"
            className={classes.bannerMessageCTA}
            onClick={params.onclick}
          >
            <FormattedMessage {...t[messagesConfig[messageId].buttonTextId]} />
          </button>
        )
      }
      handleDismiss={params.onDismiss ? params.onDismiss : handleAlertDismiss}
      isCloseButton={params.isCloseButton}
    />
  );
};

MessageBanner.propTypes = {
  handleAlertDismiss: PropTypes.func.isRequired,
  messageId: PropTypes.string.isRequired,
  params: PropTypes.object,
};

export default MessageBanner;
